<template>
  <div class="podrolls">
    <Podroll
      v-for="(podroll, index) in podrolls"
      :key="index"
      :podroll="podroll"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Podroll from './Podroll.vue'

export default {
  name: 'Podrolls',

  components: {
    Podroll
  },

  props: {
    podcastSlug: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      podrolls: []
    }
  },

  async mounted () {
    this.podrolls = await this.getPodcastPodrolls(this.podcastSlug)
  },

  methods: {
    ...mapActions('podcasts', ['getPodcastPodrolls'])
  }
}
</script>

<style lang="scss" scoped>
.podrolls {
  display: grid;
  grid-gap: $gap * 1.5;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include display-less(tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include display-less(smart) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
