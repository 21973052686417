<template>
  <div>
    <div v-if="preload">
      <div v-show="isActive" class="commonTabsTab">
        <slot />
      </div>
    </div>
    <div v-else>
      <div v-if="isActive" class="commonTabsTab">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab',

  props: {
    title: {
      type: String,
      required: true
    },
    hasSettings: {
      type: Boolean,
      default: false
    },
    preload: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isActive: false
    }
  }
}
</script>

<style lang="scss" scoped>
.commonTab {
  width: 100%;
}
</style>
