<template>
  <div class="episodes-list">
    <h3
      v-if="isWithTitle"
      class="episodes-list__legend"
    >
      {{ title }}
    </h3>
    <template v-if="!isLoading">
      <ul
        v-if="episodeListElementComponent"
        class="episodes-list__list"
        :data-testid="testId"
      >
        <template v-for="(item, index) in episodes">
          <li
            :key="'episode-list-item-' + index"
            :data-testid="testId + 'Item'"
            class="episodes-list__item"
          >
            <component
              :is="episodeListElementComponent"
              v-if="!item.itemType"
              :content="item"
              :podcast="podcast"
              :public-link="readOnly"
              @onPlayEpisode="setCurrentPlayingPodcastOfEpisode"
            />

            <h3
              v-else-if="item.itemType && item.value"
              class="season-title"
              v-html="getSeasonTitle(item.value)"
            />
          </li>
        </template>
      </ul>
    </template>
    <div v-if="$slots.settings" class="episodes-list__settings">
      <slot name="settings" />
    </div>
    <Loader v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Episode from '@/components/modules/episode/list-item'
import EpisodeApi from '@/components/modules/episode/list-item-api'
import Loader from '@/components/common/Loader/Loader.vue'

export default {
  components: {
    Loader,
    Episode,
    EpisodeApi
  },

  props: {
    isWithTitle: {
      type: Boolean,
      default: true
    },
    podcast: {
      type: Object,
      default () {
        return {}
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String,
      default: 'episodesList'
    },
    episodes: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      shareIndicator: 0,
      episodeListElementComponent: 'Episode'
    }
  },

  computed: {
    pages () {
      return Math.ceil(parseInt(this.podcast.episodesCount) / parseInt(this.limit))
    }
  },

  created () {
    if (this.podcast.episodes) {
      this.list = [...this.podcast.episodes]
    }
  },

  methods: {
    ...mapActions('podcasts', ['getEpisodes']),
    ...mapActions('player', ['setCurrentPlayingPodcast']),

    setShareIndicator () {
      this.shareIndicator = Math.random()
    },

    getSeasonTitle (season) {
      if (!season) {
        return '&nbsp;'
      }
      return this.$t('single_words.season') + ' ' + season
    },

    setCurrentPlayingPodcastOfEpisode () {
      this.setCurrentPlayingPodcast(this.podcast)
    }
  }
}
</script>

<style lang="scss" scoped>
.episodes-list {

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &__legend {
    color: #fff;
    display: flex;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: $gap;
    text-transform: none;
    align-items: center;
    justify-content: space-between;
    @include display-less(smart) {
      gap: $gap * 0.5;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__item {
    margin-bottom: $gap;
  }

  &__settings {
    margin-top: $gap * 2;
    margin-left: auto;
    width: fit-content;
  }
}
.season-title {
  color: #fff;
  width: 100%;
  margin: 6px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
  text-transform: uppercase;
}
</style>
